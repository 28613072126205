import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));   

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function checkOutTable({cities}) {
  

  return cities && cities.length > 0 &&  (
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>City Name</StyledTableCell>
            <StyledTableCell align="right">Check In</StyledTableCell>
            <StyledTableCell align="right">Check out</StyledTableCell>
            <StyledTableCell align="right">Nights</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cities.map((city,index) => (
            <>
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
              {city.city_name}
              </StyledTableCell>
              <StyledTableCell align="right">{moment(city.checkIn).format("MMM DD YYYY")}</StyledTableCell>
              <StyledTableCell align="right">{moment(city.checkOut).format("MMM DD YYYY")}</StyledTableCell>
              <StyledTableCell align="right">{city.number_of_nights}</StyledTableCell>
            </StyledTableRow>   
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    );
}