import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const NumberInput = ({ name, control, max,...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <>
          <TextField
            onKeyDown={(e) => {
              if (['+', 'e', 'E', '-'].includes(e.key)) {
                e.preventDefault();
              }
            }}
            type="number"
            {...field}
            {...props}
            InputProps={{
              onWheel: (e) => e.target.blur(),
              inputProps: { max, min:0 },
            }}
            helperText={error ? error.message : null}
            error={!!error}
            variant="outlined"
          />
        </>
      )}
    />
  );
};

export default NumberInput;
