// import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Autocomplete,
  Button,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import DateInput from "../../../Components/CommonComponents/DateInput";
import { TextInput } from "../../../Components/CommonComponents/TextInput";
import SelectInput from "../../../Components/CommonComponents/SelectInput";
import NumberInput from "../../../Components/CommonComponents/NumberInput";
import CheckOutTable from "../Table";
import Note from "../../../Components/CommonComponents/Note";
import {
  SOMETHING_WENT_WRONG,
  ADD,
  selectStatues,
  extraMattress,
  meal_Plans,
} from "../../../utils";
import _ from "lodash";
import debounce from "lodash/debounce";
import {
  addPackageDetails,
  getVehicleService,
  getCitiesService,
  getRouteService,
  getStatesService,
} from "../../../services/home";
// lodash

const defaultValues = {
  travel_date: new Date(),
  route_id: "",
  state_id: "",
  meal_plan: "",
  pickup_to: "",
  pickup_from: "",
  hotel_status: "",
  extra_adults: "",
  service_charge: "",
  select_vehicle: "",
  number_of_nights: "",
  number_of_couples: "",
  service_charge_per_child: "",
  service_charge_per_adults: "",
  service_charge_per_couple: "",
  child_above_five_years_with_mattress: "",
  child_above_five_years_without_mattress: "",
};

function Form({ status, setLoading }) {
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [routePayload, setRoutePayload] = useState({
    vehicle: "",
    pickupFrom: "",
    pickupTo: "",
    night: "",
    state_id:"",
  });
  const [routeDetail, setRouteDetail] = useState({});

  const navigate = useNavigate();

  let schema = {};

  if (status === selectStatues.HotelWithTransport) {
    schema = Yup.object().shape({
      route_id: Yup.string().required("Required"),
      meal_plan: Yup.string().required("Required"),
      state_id: Yup.string().required("Required"),
      pickup_to: Yup.string().required("Required"),
      pickup_from: Yup.string().required("Required"),
      travel_date: Yup.string().required("Required"),
      // extra_adults: Yup.string().required("Required"),
      select_vehicle: Yup.string().required("Required"),
      number_of_nights: Yup.string().required("Required"),
      // number_of_couples: Yup.string().required("Required"),
      // service_charge_per_couple: Yup.string().required("Required"),
      // service_charge_per_adults: Yup.string().required("Required"),
      // child_above_five_years_with_mattress: Yup.string().required("Required"),
      // child_above_five_years_without_mattress: Yup.string().required("Required"),
    });
  } else if (status === selectStatues.Hotel) {
    schema = Yup.object().shape({
      route_id: Yup.string().required("Required"),
      meal_plan: Yup.string().required("Required"),
      state_id: Yup.string().required("Required"),
      travel_date: Yup.string().required("Required"),
      // extra_adults: Yup.string().required("Required"),
      number_of_nights: Yup.string().required("Required"),
      // number_of_couples: Yup.string().required("Required"),
      // service_charge_per_couple: Yup.string().required("Required"),
      // service_charge_per_adults: Yup.string().required("Required"),
      // child_above_five_years_with_mattress: Yup.string().required("Required"),
      // child_above_five_years_without_mattress: Yup.string().required("Required"),
    });
  } else {
    schema = Yup.object().shape({
      route_id: Yup.string().required("Required"),
      state_id: Yup.string().required("Required"),
      pickup_to: Yup.string().required("Required"),
      travel_date: Yup.string().required("Required"),
      pickup_from: Yup.string().required("Required"),
      select_vehicle: Yup.string().required("Required"),
      // service_charge: Yup.string().required("Required"),
      number_of_nights: Yup.string().required("Required"),
      // number_of_couples: Yup.string().required("Required"),
    });
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm({
    mode: "onChange",
    values: defaultValues,
    resolver: yupResolver(schema),
  });

  const handleInputChangeValue = async (event, inputValue) => {
    const params = {
      ...routePayload,
      hotel_status: status,
      vehilce: routePayload.vehicle,
      search: inputValue,
    };
    if (
      (routePayload.pickupFrom &&
        routePayload.pickupTo &&
        routePayload.vehicle &&
        routePayload.night && 
        routePayload.state_id) ||
      (status === selectStatues.Hotel && routePayload.night && routePayload.state_id)
    ) {
      setLoading(true);
      const data = await getRouteService(params, setLoading);
      setOptions(data || []);
    } else {
      setOptions([]);
    }
  };

  const getCities = async (id) => {
    setLoading(true);
    const data = await getCitiesService(id,setLoading);
    setCityOptions(data || []);
  };

  const getStates = async () => {
    setLoading(true);
    const data = await getStatesService({is_route:1},setLoading);
    setStateOptions(data || []);
  };

  const getVehicle = async () => {
    setLoading(true);
    const data = await getVehicleService(setLoading);
    setVehicles(data || []);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const result = await addPackageDetails(
      { ...data, hotel_status: status },
      setLoading
    );
    if (result) {
      if (status === selectStatues.Transport) {
        navigate(`calculation/${result?.id}`);
      } else {
        navigate(`hotels/${result?.id}`);
      }
    }
  };

  const handleRouteDetail = async (event, value) => {
    if (value) {
      setValue("route_id", value.id);
      try {
        const resp = await axios.get(`public/getRouteDetails.php?id=${value?.id}`);
        const result = resp.data;
        if (result.status) {
          // let checkOut = new Date(getValues().travel_date);
          // let checkInDateCal = new Date(getValues().travel_date);
          // let checkIn = new Date(getValues().travel_date);

          // response?.data?.data?.cities.forEach((ele) => {
          //   ele.checkIn = checkIn;
          //   ele.checkOut = checkOut.setDate(
          //     checkOut.getDate() + Number(ele.number_of_nights)
          //   );
          //   checkIn = checkInDateCal.setDate(
          //     checkInDateCal.getDate() + Number(ele.number_of_nights)
          //   );
          // });
          setRouteDetail(result?.data);
        } else {
          setRouteDetail(result?.data);
          toast.error(result?.message || SOMETHING_WENT_WRONG, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (err) {
        toast.error(err.message || SOMETHING_WENT_WRONG, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else setRouteDetail({});
  };

  useEffect(() => {
    getVehicle();
    getStates();
  }, []);

  useEffect(() => {
    handleInputChangeValue();
    setRouteDetail([]);
  }, [routePayload]);

  useEffect(() => {
    reset();
    setRoutePayload({});
  }, [status]);

  const debouncedOnChange = debounce(handleInputChangeValue, 500);

  return (
    <>
      <form name="basicDetail" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <InputLabel
              sx={{
                display: "flex",
                // flexDirection:'column',
                // justifyContent: "center",
                fontWeight: 700,
              }}
            >
              Travel Date:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={10}>
            <Controller
              name="travel_date"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DateInput
                      name="travel_date"
                      control={control}
                      label="Travel Date"
                      disablePast={true}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <InputLabel
              sx={{
                display: "flex",
                fontWeight: 700,
              }}
            >
              Number Of Nights:
            </InputLabel>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={status === selectStatues.Transport ? 10 : 4}
          >
            <NumberInput
              onChange={(e) => {
                setValue("number_of_nights", e.target.value);
                setRoutePayload({
                  ...routePayload,
                  night: e.target.value,
                });
              }}
              control={control}
              label="Number Of Nights"
              name="number_of_nights"
              fullWidth
            />
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12} lg={2}>
            <InputLabel
              sx={{
                display: "flex",
                fontWeight: 700,
              }}
            >
              Number Of Adults:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <NumberInput
              control={control}
              label={"Number Of Adults"}
              name="number_of_couples"
              fullWidth
            />
          </Grid> */}
          {status !== selectStatues.Transport && (
            <>
              {/* <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700,
                  }}
                >
                  Extra Adult:
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <NumberInput
                  control={control}
                  label="Extra Adult"
                  name="extra_adults"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "pre-line",
                  }}
                >
                  Child above 5 years with matterss:
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <NumberInput
                  name="child_above_five_years_with_mattress"
                  control={control}
                  label="Child above 5 years with mattress"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700,
                    whiteSpace: "pre-line",
                  }}
                >
                  Child above 5 years without matterss:
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <NumberInput
                  name="child_above_five_years_without_mattress"
                  control={control}
                  label="Child above 5 years without mattress"
                  fullWidth
                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700,
                  }}
                >
                  Meal Plan:
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <SelectInput
                  name="meal_plan"
                  control={control}
                  label="Meal Plan"
                  options={meal_Plans}
                  optionKey={"label"}
                  fullWidth
                  onChange={(e,value) => {
                    setValue("meal_plan", value?.id);
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12} md={12} lg={2}>
            <InputLabel
              sx={{
                display: "flex",
                fontWeight: 700,
              }}
            >
              Select Destination:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={10}>
            <SelectInput
              name="state_id"
              control={control}
              label="Select Destination"
              options={stateOptions}
              optionKey={"state"}
              fullWidth
              onChange={(e,value) => {
                setValue("state_id", value?.id);
                setRoutePayload({
                  ...routePayload,
                  state_id: value?.id,
                });
                if(value?.id)
                  getCities(value?.id)
                else 
                  setCityOptions([])
              }}
            />
          </Grid>
          {status !== selectStatues.Hotel && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  style={{
                    display: "flex",
                    fontWeight: 700,
                  }}
                >
                  Pickup From :
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <SelectInput
                  name="pickup_from"
                  control={control}
                  label="Pickup from"
                  options={cityOptions}
                  optionKey={"pickup_location_name"}
                  fullWidth
                  onChange={(e,value) => {
                    setValue("pickup_from", value?.id);
                    setRoutePayload({
                      ...routePayload,
                      pickupFrom: value?.id,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  style={{
                    display: "flex",
                    fontWeight: 700,
                  }}
                >
                  Drop Off :
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <SelectInput
                  name="pickup_to"
                  control={control}
                  label="Drop Off"
                  options={cityOptions}
                  optionKey={"pickup_location_name"}
                  fullWidth
                  onChange={(e,value) => {
                    setValue("pickup_to", value?.id);
                    setRoutePayload({
                      ...routePayload,
                      pickupTo: value?.id,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  style={{
                    display: "flex",
                    fontWeight: 700,
                  }}
                >
                  Select Vehicle :
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <SelectInput
                  name="select_vehicle"
                  control={control}
                  label="Vehicle"
                  options={vehicles}
                  optionKey={"name"}
                  fullWidth
                  onChange={(e,value) => {
                    setValue("select_vehicle", value?.id);
                    setRoutePayload({
                      ...routePayload,
                      vehicle: value?.id,
                    });
                  }}
                />
              </Grid>
            </>
          )}

          {status !== selectStatues.Transport && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700,
                  }}
                >
                  Service charge per adult :
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <NumberInput
                  control={control}
                  label="Service charge per adult"
                  name="service_charge_per_couple"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "pre-line",
                  }}
                >
                  Service charge per extra adults:
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <NumberInput
                  control={control}
                  label="Service charge per extra adults"
                  name="service_charge_per_adults"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    whiteSpace: "pre-line",
                  }}
                >
                  Service charge per extra child's:
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <NumberInput
                  control={control}
                  label="Service charge per child's"
                  name="service_charge_per_child"
                  fullWidth
                />
              </Grid>
            </>
          )}

          {status === selectStatues.Transport && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700,
                  }}
                >
                  Service Charge :
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <NumberInput
                  control={control}
                  label="Service Charge"
                  name="service_charge"
                  fullWidth
                />
              </Grid>
            </>
          )}

          {((status === selectStatues.Hotel && routePayload.night && routePayload.state_id ) ||
            (
              routePayload.pickupFrom &&
              routePayload.pickupTo &&
              routePayload.vehicle &&
              routePayload.night && 
              routePayload.state_id
            )) && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <InputLabel
                  sx={{
                    display: "flex",
                    fontWeight: 700,
                  }}
                >
                  Select Route :
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <Controller
                  name="route_id"
                  control={control}
                  defaultValue={[]}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        id="route-autocomplete"
                        options={
                          !options ? [{ label: "Loading...", id: 0 }] : options
                        }
                        getOptionLabel={(option) => option.route_name || ""}
                        // value={selectedOption}
                        onInputChange={debouncedOnChange}
                        onChange={handleRouteDetail}
                        renderInput={(params) => (
                          <TextField
                            {...field}
                            label="Select Route"
                            variant="outlined"
                            inputRef={ref}
                            error={!!error}
                            helperText={error?.message}
                            {...params}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} mb={2}>
            <Note title={"Itinerary"} description={routeDetail.itinerary} />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              disabled={isSubmitting}
            >
              {status === selectStatues.Transport ? "Submit" : "Search Hotels"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default Form;
