import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG,ADD } from "../utils";
import axios from "../utils/axios";

export const addPackageDetails = async (data,setLoading) => {
  // data.extra_adults = Number(data.extra_adults);
  data.select_vehicle = Number(data.select_vehicle);
  data.service_charge = Number(data.service_charge);
  // data.number_of_couples = Number(data.number_of_couples);
  data.service_charge_per_couple = Number(data.service_charge_per_couple);
  data.service_charge_per_adults = Number(data.service_charge_per_adults);
  data.service_charge_per_child = Number(data.service_charge_per_child);
  // data.child_above_five_years_with_mattress = Number(data.child_above_five_years_with_mattress);
  // data.child_above_five_years_without_mattress = Number(data.child_above_five_years_without_mattress);

  try {
    const resp = await axios.post(`public/addPackageDetails.php`,data);
    const result = resp.data;
    if (result.status === "success") {
      setLoading(false);
      toast.success(`Package ${ADD}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return result?.data;
    } else {
      setLoading(false);
      toast.error(result.message || SOMETHING_WENT_WRONG, {
          position: toast.POSITION.TOP_RIGHT,
      });
      return "";
    }
  } catch (err) {
    setLoading(false);
    toast.error(err.message || SOMETHING_WENT_WRONG, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getVehicleService = async (setLoading) => {
  try {
    const resp = await axios.get(`public/publicAllCar.php`);
    const result = resp.data;
    if (result.status) {
      setLoading(false);
      return result.data;
    } else {
      setLoading(false);
      toast.error(result.message || SOMETHING_WENT_WRONG, {
          position: toast.POSITION.TOP_RIGHT,
      });
      return [];
    }
  } catch (err) {
    setLoading(false);
    toast.error(err.message || SOMETHING_WENT_WRONG, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

// export const getCitiesService = async (setLoading) => {
//   try {
//     const resp = await axios.get(`https://mtmy.bharatguruji.com/publicGetAllCity.php`);
//     const result = resp.data;
//     if (result.status) {
//       setLoading(false);
//       return result.data;
//     } else {
//       setLoading(false);
//       toast.error(result.message || SOMETHING_WENT_WRONG, {
//           position: toast.POSITION.TOP_RIGHT,
//       });
//       return [];
//     }
//   } catch (err) {
//     setLoading(false);
//     toast.error(err.message || SOMETHING_WENT_WRONG, {
//       position: toast.POSITION.TOP_RIGHT,
//     });
//   }
// };

export const getCitiesService = async (id,setLoading) => {
  try {
    const resp = await axios.get(`public/getPickupLocation.php?id=${id}`);
    const result = resp.data;
    if (result.status) {
      setLoading(false);
      return result.data;
    } else {
      setLoading(false);
      toast.error(result.message || SOMETHING_WENT_WRONG, {
          position: toast.POSITION.TOP_RIGHT,
      });
      return [];
    }
  } catch (err) {
    setLoading(false);
    toast.error(err.message || SOMETHING_WENT_WRONG, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getStatesService = async (params,setLoading) => {
  try {
    const resp = await axios.get(`publicGetallState.php`,{params});
    const result = resp.data;
    if (result.status) {
      setLoading(false);
      return result.data;
    } else {
      setLoading(false);
      toast.error(result.message || SOMETHING_WENT_WRONG, {
          position: toast.POSITION.TOP_RIGHT,
      });
      return [];
    }
  } catch (err) {
    setLoading(false);
    toast.error(err.message || SOMETHING_WENT_WRONG, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getRouteService = async (params,setLoading) => {
  try {
    const resp = await axios.get(`public/getRoute.php`, { params });
    const result = resp.data;
    if (result.status === "success")  {
      setLoading(false);
      return result.data;
    } else {
      setLoading(false);
      toast.error(result.message || SOMETHING_WENT_WRONG, {
          position: toast.POSITION.TOP_RIGHT,
      });
      return [];
    }
  } catch (err) {
    setLoading(false);
    toast.error(err.message || SOMETHING_WENT_WRONG, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
