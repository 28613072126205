import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG } from "../utils";
import axios from "../utils/axios";

export const calculationDetailsService = async (id,setLoading) => {
    try {
      const resp = await axios.get(`public/publicGetCalculation.php?id=${id}`);
      const result = resp.data;
      if (result.status === "success") {
        setLoading(false);
        return result.data;
      } else {
        setLoading(false);
        toast.error(result.message || SOMETHING_WENT_WRONG, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return [];
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message || SOMETHING_WENT_WRONG, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
};