import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";

function CustomTabLabel({ label, subtitle }) {
  return (
    <div>
      <Typography>{label}</Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </div>
  );
}

export default function TabsMenu({ selectedId, cities, changeCities }) {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={selectedId}
        onChange={changeCities}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="icon label tabs example"
      >
        {cities.map((city, index) => (
          <Tab
            key={index}
            label={
              <CustomTabLabel
                label={city.label}
                subtitle={`${moment(city.checkIn).format(
                  "DD MMM YYYY"
                )} - ${moment(city.checkOut).format("DD MMM YYYY")}`}
              />
            }
            value={city?.id}
            style={{
              backgroundColor: city.id === selectedId ? "#f5a01b" : "",
              color: city.id === selectedId ? "white" : "black",
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
