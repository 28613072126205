import React from "react";
import Typography from "@mui/material/Typography";

const Title = ({name}) => {
  return (
    <Typography
      variant="h6"
      sx={{ textDecoration: "underline" }}
      fontWeight={"1000"}
    >
      {name}
    </Typography>
  );
};

export default Title;
