import { red, orange, blue, green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#767676',
      light: '#767676',
      dark: '#767676',
      contrastText: '#ffffff',
    },
    secondary: {
      main: orange[400],
      light: orange[300],
      dark: orange[500],
      contrastText: '#ffffff',
    },
    success: {
      main: green[400],
      light: green[300],
      dark: green[500],
      contrastText: '#ffffff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#282c34',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        padding: '20px 10px',
        margin: '10px',
        backgroundColor: '#fff',
      },
    },
    MuiButton: {
      root: {
        margin: '5px',
      },
    },
  },
});
export default theme;