import { Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function DateInput({ name, control, label, maxDate, disablePast, ...props }) {
  
  return (    
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error }, formState }) => (
          <DatePicker
          {...field}
          format="dd/MM/yyyy"
          label={label}
          disablePast={disablePast}
          slotProps={{
            textField: {
              fullWidth: true,
              helperText: error ? error.message : null,
              error: !!error,
              variant: "outlined",
              ...props,
            },
          }}
        />
            
        )}
      />
    </LocalizationProvider>
  );
}
