import React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Header = ({ params, handleSearchChange, handleSubmit }) => {
  return (
    <>
      <Typography variant="h4">Hotels</Typography>
      <Grid container sx={{display:'flex',justifyContent:'space-between', paddingY:2}} >
        <Grid item xs={12} sm={12} md={6} lg={6} >
          <TextField
            id="search"
            type="search"
            label="Search Hotel"
            value={params?.search}
            size="small"
            onChange={handleSearchChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>   
        <Grid  xs={12} sm={12} md={6} lg={6} container display={'flex'} justifyContent={"flex-end"} >
          <Button
            variant="contained"
            color="secondary"
            className="rounded"
            type="submit"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
