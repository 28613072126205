import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'blue', // Customize the background color
  },
}));
   
const StickyHeader = () => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar style={{
        backgroundColor: '#00aeef',
        height: '100px'
      }}>
        <Typography variant="h6">
          GOA FORM
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default StickyHeader;