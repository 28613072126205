import { Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function SelectInput({
  name,
  control,
  options,
  optionKey,
  onChange,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field:{value}, fieldState: { error }, formState }) => (
        <Autocomplete
          options={!options ? [{ label: "Loading...", id: 0 }] : options}
          getOptionLabel={(option) => option[optionKey] || ""}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              value={value}
              name={name}
              {...props}
              variant="outlined"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        // <FormControl fullWidth error={!!error}>
        //   <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        //   <Select
        //     labelId="demo-simple-select-helper-label"
        //     id="demo-simple-select-helper"
        //     label={label}
        //     {...field}
        //     {...props}
        //   >
        //     <MenuItem value="">Select {label}</MenuItem>

        //     {option &&
        //       option.map((option, index) => (
        //         <MenuItem value={option.id} key={index}>
        //           {option[optionKey]}
        //         </MenuItem>
        //       ))}
        //   </Select>
        //   {error ? <FormHelperText>{error.message}</FormHelperText> : null}
        // </FormControl>
      )}
    />
  );
}
