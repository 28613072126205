import React, { useEffect, useState, useCallback } from "react";
import "./App.css";

import { Routes, Route } from "react-router-dom";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./views/home";
import Calculation from "./views/calculation";
import ErrorPage404 from "./views/404";
import Hotel from "./views/hotel";
import { CheckboxProvider } from "./Components/CheckboxContext";
import theme from "./theme";
import { options } from "./utils/background-config";

function App() {
  const [myValue, setMyValue] = useState("");

  useEffect(() => {
    const storedValue = localStorage.getItem("formType");

    if (storedValue) {
      setMyValue(storedValue);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("formType", myValue);
  }, []);

  // useEffect(() => {
  //   initParticlesEngine(async (engine) => {
  //     await loadSlim(engine);
  //   });
  // }, []);

  // const particlesLoaded = useCallback(async (container) => {
  //   await console.log(container);
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <div
        // style={{
        //   backgroundImage: 'url("image/pexels-photo-949587.webp")',
        //   backgroundPosition: "center",
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        //   width: "100vw",
        //   height: "100vh",
        // }}
      >
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/hotels/:id" element={<Hotel />} />
          <Route path="/calculation/:id" element={<Calculation />} />
          <Route path="*" element={<ErrorPage404 />} />
        </Routes>
        <ToastContainer />
        {/* <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
          /> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
