import { toast } from "react-toastify";
import { SOMETHING_WENT_WRONG, REQUIRED } from "../utils";
import axios from "../utils/axios";

export const getCheckOutCitiesService = async (id, setLoading) => {
  try {
    const resp = await axios.get(`public/publicGetCheckoutCity.php?id=${id}`);
    const result = resp.data;
    if (result.status === "success") {
        setLoading(false);
        return result.data;
    } else {
      setLoading(false);
      toast.error(result.message || SOMETHING_WENT_WRONG, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return [];
    }
  } catch (err) {
    setLoading(false);
    toast.error(err.message || SOMETHING_WENT_WRONG, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const getHotelByCityIdService = async (params,setLoading) => {
    try {
      const resp = await axios.get(`public/publicGetHotelByCity.php`, { params });
      const result = resp.data;
      if (result.status === "success") {
        setLoading(false);
        return result.data;
      } else {
        setLoading(false);
        toast.error(result.message || SOMETHING_WENT_WRONG, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return [];
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message || SOMETHING_WENT_WRONG, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  export const getRoomByHotelIdService = async (id,setLoading) => {
    try {
      const resp = await axios.get(`public/publicGethotelRoom.php?id=${id}`);
      const result = resp.data;
      if (result.status === "success") {
        setLoading(false);
        return result.data;
      } else {
        setLoading(false);
        toast.error(result.message || SOMETHING_WENT_WRONG, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return [];
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message || SOMETHING_WENT_WRONG, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  export const addHotelRooms = async (data,setLoading) => {
    try {
      const resp = await axios.post(`public/addPackageRoomDetails.php`,data);
      const result = resp.data;
      if (result.status === "success") {
        setLoading(false);
        toast.success('Room has been added successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return true;
      } else {
        setLoading(false);
        toast.error(result.message || SOMETHING_WENT_WRONG, {
            position: toast.POSITION.TOP_RIGHT,
        });
        return [];
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message || SOMETHING_WENT_WRONG, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };  
