import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Grid, Divider, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import moment from "moment";
import Loading from "../../Components/CommonComponents/Loading";
import { calculationDetailsService } from "../../services/calculation";
import Note from "../../Components/CommonComponents/Note";
import Title from "./Title";

const useStyles = makeStyles({
  bold: {
    fontWeight: 1000,
  },
  table: {
    minWidth: 650, // Example: Set a minimum width for the entire table
  },
  tableCell: {
    width: "100px", // Set the width for each cell
  },
});

const Calculation = () => {
  const { id } = useParams();
  const classes = useStyles();
  const downloadRef = React.createRef();

  const [loading, setLoading] = useState(true);
  const [calculationDetail, setCalculationDetail] = useState({});

  const handleDownload = () => {
    const content = downloadRef.current;
    html2pdf(content, {
      margin: 2,
      filename: "cost_details.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      pagebreak:{mode: 'avoid-all'},
      jsPDF: { unit: "mm", format: "letter", orientation: "portrait" },
    });
  };

  const calculationDetails = async () => {
    const data = await calculationDetailsService(id, setLoading);
    if (data) {
      setCalculationDetail(data);
    } else {
      setCalculationDetail({});
    }
  };

  useEffect(() => {
    calculationDetails();
  }, [id]);

  return (
    <>
      {loading && <Loading loading={loading} />}
      <Paper 
        // sx={{
        //   marginY: "5%",
        //   marginX: "10%",
        // }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 2 }} className="test">
          <Button
            variant="contained"
            color="secondary"
            className="rounded"
            type="button"
            onClick={handleDownload}
          >
            Download pdf
          </Button>
        </Box>
        <Box sx={{ margin:2,padding: 4, border: '1px solid black', borderRadius:2 }} ref={downloadRef} >
          <Grid container spacing={2}>
            {/* <Grid item xs={3} sm={3} md={3} lg={3}>
              <Typography>Ref. Query ID : 2215</Typography>
              <Typography>Date : 22-11-2023</Typography>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h6" fontWeight={"1000"}>
                HAPPY HOLIDAYS(FREELANCER)
              </Typography>
              <Typography>
                415 - STAR CHEMABAR,HARI HAR, CHOWK RAJKOT, RAJKOT
              </Typography>
              <Typography>
                <b>EMAIL</b> : HAPPYHOLIDAYS0000@GMAIL.COM <b>MOBILE</b> :
                9727338663
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider orientation="horizontal" flexItem />
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Ref. Query ID : {id}</Typography>
              <Typography className={classes.bold}>
                {moment().format("MMMM Do YYYY, h:mm:ss a")}
              </Typography>
            </Grid>
            {calculationDetail?.quoteDetails &&
              calculationDetail?.quoteDetails.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Title name="Quotation Detail" />
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.bold}>
                              Visit Places
                            </TableCell>
                            <TableCell align="left">
                              {calculationDetail?.quoteDetails[0]?.visit_places}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.bold}>
                              Travel Date
                            </TableCell>
                            <TableCell align="left">
                              {moment(
                                calculationDetail?.quoteDetails[0]?.travel_date
                              ).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell className={classes.bold}>
                              Total Nights
                            </TableCell>
                            <TableCell align="left">
                              {calculationDetail?.quoteDetails[0]?.total_days}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.bold}>
                              Total Passenger
                            </TableCell>
                            <TableCell align="left">
                              {
                                calculationDetail?.quoteDetails[0]
                                  ?.total_passenger
                              }
                            </TableCell>
                            <TableCell className={classes.bold}>
                              Quote Include
                            </TableCell>
                            <TableCell align="left">
                              {
                                calculationDetail?.quoteDetails[0]
                                  ?.quote_include
                              }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              )}
            {calculationDetail?.vehicleDetails &&
              calculationDetail?.vehicleDetails.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Title name="Vehicle Detail" />
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.bold}>
                              Vehicle
                            </TableCell>
                            <TableCell className={classes.bold}>
                              Pick-Up
                            </TableCell>
                            <TableCell className={classes.bold}>Drop</TableCell>
                            {calculationDetail?.quoteDetails && calculationDetail?.quoteDetails[0]?.hotel_status === '2' ?  <TableCell className={classes.bold}>Price</TableCell> : null}
                            {/* <TableCell className={classes.bold}>Price</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              {calculationDetail?.vehicleDetails[0]?.car_name}
                            </TableCell>
                            <TableCell>
                              {calculationDetail?.vehicleDetails[0]?.pick_up}
                            </TableCell>
                            <TableCell>
                              {calculationDetail?.vehicleDetails[0]?.drop}
                            </TableCell>
                            {calculationDetail?.quoteDetails && calculationDetail?.quoteDetails[0]?.hotel_status === '2' ?  <TableCell className={classes.bold}>{calculationDetail?.costDetails?.total_cost}</TableCell> : null}

                            {/* <TableCell>
                              {calculationDetail?.vehicleDetails[0]?.price}
                            </TableCell> */}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              )}
              {calculationDetail?.quoteDetails &&
              calculationDetail?.quoteDetails.length > 0 && calculationDetail?.quoteDetails[0]?.hotel_status !== '2' && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                    
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Title name="Cost Breakdown" />
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell>Hotel</TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className={classes.bold}
                            >
                              Rs {calculationDetail?.costDetails?.adult_price}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Transportation</TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className={classes.bold}
                            >
                              Rs {calculationDetail?.costDetails?.vehice}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Extra Adult</TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className={classes.bold}
                            >
                              Rs {calculationDetail?.costDetails?.extra_adult}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Child With Bed</TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className={classes.bold}
                            >
                              Rs{" "}
                              {calculationDetail?.costDetails?.child_with_bed}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Child Without Bed</TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className={classes.bold}
                            >
                              Rs{" "}
                              {
                                calculationDetail?.costDetails
                                  ?.child_without_bed
                              }
                            </TableCell>
                          </TableRow>
                          
                          <TableRow>
                            <TableCell>
                              <Typography variant="h6" fontWeight={"1000"}>
                                Grand Total
                              </Typography>{" "}
                            </TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Typography variant="h6" fontWeight={"1000"}>
                                Rs {calculationDetail?.costDetails?.total_cost}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              )}
            {calculationDetail?.hotelDetails &&
              calculationDetail?.hotelDetails.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Title name="Hotels Details" />
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>No.</TableCell>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>
                              City
                            </TableCell>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>
                              Hotel
                            </TableCell>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>Room</TableCell>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>
                              Meal Plan
                            </TableCell>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>
                              Check In
                            </TableCell>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>
                              Check Out
                            </TableCell>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>
                              No. Of Nights
                            </TableCell>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>
                              Adult
                            </TableCell>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>
                              Extra Adult
                            </TableCell>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>
                              Child With Bed
                            </TableCell>
                            <TableCell style={{margin:0,padding:5}} className={classes.bold}>
                              Child Without Bed
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {calculationDetail?.hotelDetails.map((ele, index) => (
                            <TableRow key={index}>
                              <TableCell style={{margin:0,padding:5}}>{index + 1}</TableCell>
                              <TableCell style={{margin:0,padding:5}}>{ele?.city_name}</TableCell>
                              <TableCell style={{margin:0,padding:5}}>{ele?.hotel_name}</TableCell>
                              <TableCell style={{margin:0,padding:5}}>
                                {ele?.number_of_rooms} {ele?.room_type}
                              </TableCell>
                              <TableCell style={{margin:0,padding:5}}>
                                {" "}
                                {ele?.meal_plan.toUpperCase()}
                              </TableCell>
                              <TableCell style={{margin:0,padding:5}}>{ele?.check_in_date}</TableCell>
                              <TableCell style={{margin:0,padding:5}}>{ele?.check_out_date}</TableCell>
                              <TableCell style={{margin:0,padding:5}}> {ele?.total_nights}</TableCell>
                              <TableCell style={{margin:0,padding:5}}>{ele?.adult}</TableCell>
                              <TableCell style={{margin:0,padding:5}}>
                                {ele?.extra_adult ? ele?.extra_adult : "-"}
                              </TableCell>
                              <TableCell style={{margin:0,padding:5}}>
                                {ele?.child_with_mattress
                                  ? ele?.child_with_mattress
                                  : "-"}
                              </TableCell>
                              <TableCell style={{margin:0,padding:5}}>
                                {ele?.child_without_mattress
                                  ? ele?.child_without_mattress
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              )}
           {calculationDetail?.quoteDetails && calculationDetail?.quoteDetails[0]?.hotel_status !== '2' ?  <Grid item xs={12} sm={12} md={12} lg={12} mb={5}>
              {/* Ch.W.M - Child With Mattress | Ch.W.O.M - Child Without Mattress | */}
              CP - Breakfast Only | MAP - Breakfast & Dinner | AP - Breakfast,
              Lunch & dinner
            </Grid>: null}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {calculationDetail?.itineraryDetails &&
                calculationDetail?.itineraryDetails.length > 0 && (
                  <Note
                    title={"Itinerary"}
                    description={
                      calculationDetail?.itineraryDetails[0]?.itinerary
                    }
                  />
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {calculationDetail?.termCondition &&
                calculationDetail?.termCondition.length > 0 && (
                  <Note
                    title={"Terms And Conditions"}
                    description={
                      calculationDetail?.termCondition[0]?.term_condition
                    }
                  />
                )}
            </Grid>
          </Grid>

          <Typography
            sx={{ display: "flex", justifyContent: "center", fontWeight: 1000 }}
            mt={1}
            variant="h6"
          >
            *** Happy Journey ***
          </Typography>
        </Box>
      </Paper>
    </>
  );
};

export default Calculation;
