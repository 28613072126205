export * from './Message';


export const selectStatues = {
    'HotelWithTransport':0,
    'Hotel':1,
    'Transport':2
}

export const meal_Plans = [
    { id: "cp", label: "CP" },
    { id: "map", label: "MAP" },
    { id: "ap", label: "Ap" },
];
  
export const extraMattress = [
    { id: "yes", label: "Yes" },
    { id: "no", label: "No" },
];