import * as React from "react";
import  {Button,Box, Typography} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Rating from "@mui/material/Rating";
import { DataGrid } from "@mui/x-data-grid";

//icon
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IconButton from '@mui/material/IconButton';

export default function Table({ hotels, handleOpenDialog }) {
  const columns = [
    {
      field: "row_id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "hotel_name",
      headerName: "Hotel Name",
      flex: 1,
      minWidth:250
    },
    // {
    //   field: "mobile_number",
    //   headerName: "Mobile Number",
    //   width: 250,
    // },
    {
      field: "rating",
      headerName: "Rating",
      flex: 1,
      minWidth:200,
      renderCell: (params) => <Rating readOnly value={params.value} />,
    },
    {
      field: "hotel_address",
      headerName: "Hotel Address",
      flex: 1,
      minWidth:300,
    },
    {
      field: "action",
      headerName: "Room Detail",
      // flex:1,
      minWidth: 200,
      renderCell: (params) => {
        return  params?.row?.is_hotel_selected ?  (
          // <IconButton >
          //   <CheckCircleOutlineIcon  style={{ color: 'green' }}  />
          // </IconButton>
          <Button variant="outlined" type="submit"  color="success"  >
              Selected
          </Button>
        ) : (
            <Button variant="outlined" type="submit" color="secondary" onClick={()=>handleOpenDialog(params?.id) }>
              Select
            </Button>
          // <IconButton onClick={()=>handleOpenDialog(params?.id) }>
          //   <ControlPointIcon />
          // </IconButton>
        );
      },
    },
  ];


  return (
    <Box sx={{ height: hotels.data && hotels.data.length > 0 ? 'auto' :  500   }}>
      <DataGrid
        rows={hotels.data || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
        //loading={true} 
        // checkboxSelection
        disableColumnMenu
        localeText={{ noRowsLabel: 'No Records Found.' }}
        disableRowSelectionOnClick
        backgroundColor='#fff'
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5a01b",
            color: "#fff",
            fontSize: 16,
          },
          "& .css-1w53k9d-MuiDataGrid-overlay": {
            backgroundColor: "#fff",
          },
        }}
      />
    </Box>
  );
}
