import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getCheckOutCitiesService,
  getHotelByCityIdService,
  getRoomByHotelIdService,
  addHotelRooms,
} from "../../services/hotel";
import Header from "./Header";
import Table from "./Table";
import Room from "./Room";
import Tab from "./Tab";
import Loading from "../../Components/CommonComponents/Loading";
import moment from "moment";

const Hotel = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [packageDetail, setPackageDetail] = useState("");
  const [isDialog, setIsDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [cities, setCities] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [hotel, setHotel] = useState("");
  const [city, setCity] = useState("");
  const [params, setParams] = useState({
    id: "",
    package_id: id,
    pageNumber: 1,
    pageSize: 10,
    sortBy: "cts",
    sortOrder: "desc",
    search: "",
    timestamp: new Date().getTime(),
  });

  const getCheckOutCities = async () => {
    const data = await getCheckOutCitiesService(id, setLoading);
    if (data) {
      if (data && data?.cities && data?.packageData) {
        let checkOut = new Date(data?.packageData.travel_date);
        let checkInDateCal = new Date(data?.packageData.travel_date);
        let checkIn = new Date(data?.packageData.travel_date);

        data?.cities.forEach((ele) => {
          ele.checkIn = checkIn;
          ele.checkOut = checkOut.setDate(
            checkOut.getDate() + Number(ele.number_of_nights)
          );
          checkIn = checkInDateCal.setDate(
            checkInDateCal.getDate() + Number(ele.number_of_nights)
          );
        });

        setCities(data?.cities || []);
        setParams((previous) => ({ ...previous, id: data?.cities[0]?.id }));
        setCity(data?.cities[0]?.id);
        setPackageDetail(data?.packageData);
      }
    } else {
      setCities([]);
    }
  };

  const getHotelByCityId = async () => {
    setLoading(true);
    const data = await getHotelByCityIdService(params, setLoading);
    if (data) {
      data?.data &&
        data?.data.forEach((ele, index) => (ele.row_id = index + 1));
      setHotels(data || []);
    } else {
      setHotels([]);
    }
  };

  const getRoomByHotelId = async (id) => {
    setLoading(true);
    setHotel(id);
    const data = await getRoomByHotelIdService(id, setLoading);
    if (data) {
      setRooms(data || []);
    } else {
      setRooms([]);
    }
  };

  const changeCities = async (event, newValue) => {
    setCity(newValue);
    setParams((previous) => ({ ...previous, id: newValue }));
  };

  const handleSubmit = async () => {  
    const allTrue = !cities.some((ele) => ele.is_hotel_selected === false);
    if (cities.length &&  allTrue) {
      navigate(`/calculation/${packageDetail?.id}`);
    } else {
      toast.error("Please select all city hotel.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAddRoom = async (data,reset) => {
    // let message = "";
    // let adultCount = 0;
    // let extraAdultCount = 0;
    // let childWithMattressCount = 0;
    // let childWithoutMattressCount = 0;

    // count adults ,extra adult child with mattress and without
    // data.rooms.forEach((ele, index) => {
    //   adultCount = Number(ele.adult) + adultCount;
    //   extraAdultCount = Number(ele.extra_adult) + extraAdultCount;
    //   childWithMattressCount =
    //     Number(ele.child_with_mattress) + childWithMattressCount;
    //   childWithoutMattressCount =
    //     Number(ele.child_without_mattress) + childWithoutMattressCount;
    // });

    // check adults ,extra adult child with mattress and without
    // if (Number(packageDetail.number_of_couples) !== adultCount) {
    //   message = `Please Select ${packageDetail.number_of_couples} Adults!`;
    // } else if (Number(packageDetail.extra_adults) !== extraAdultCount) {
    //   message = `Please Select ${packageDetail.extra_adults} Extra Adults!`;
    // } else if (
    //   Number(packageDetail.child_above_five_years_with_mattress) !==
    //   childWithMattressCount
    // ) {
    //   message = `Please Select ${packageDetail.child_above_five_years_with_mattress} Child With Mattress!`;
    // } else if (
    //   Number(packageDetail.child_above_five_years_without_mattress) !==
    //   childWithoutMattressCount
    // ) {
    //   message = `Please Select ${packageDetail.child_above_five_years_without_mattress} Child Without Mattress!`;
    // } else {
    // }

    // check room max occupancy
    // data.rooms.forEach((ele, index) => {
    //   let max_occupancy = 0;
    //   rooms.forEach((room, roomIndex) => {
    //     if (room.id === ele.room_id) {
    //       if (Number(ele.adult) + max_occupancy <= room?.max_occupancy) {
    //         max_occupancy = max_occupancy + Number(ele.adult);
    //       } else {
    //         message =
    //           "Please add adult, extra adult ,child with mattress and child without mattress  less then or equal to room max occupancy.";
    //         return;
    //       }

    //       if (Number(ele.extra_adult) + max_occupancy <= room?.max_occupancy) {
    //         max_occupancy = max_occupancy + Number(ele.extra_adult);
    //       } else {
    //         message =
    //           "Please add adult, extra adult ,child with mattress and child without mattress  less then or equal to room max occupancy.";
    //         return;
    //       }

    //       if (
    //         Number(ele.child_with_mattress) + max_occupancy <=
    //         room?.max_occupancy
    //       ) {
    //         max_occupancy = max_occupancy + Number(ele.child_with_mattress);
    //       } else {
    //         message =
    //           "Please add adult, extra adult ,child with mattress and child without mattress  less then or equal to room max occupancy.";
    //         return;
    //       }

    //       if (
    //         Number(ele.child_without_mattress) + max_occupancy <=
    //         room?.max_occupancy
    //       ) {
    //         max_occupancy = max_occupancy + Number(ele.child_without_mattress);
    //       } else {
    //         message =
    //           "Please add adult, extra adult ,child with mattress and child without mattress  less then or equal to room max occupancy.";
    //         return;
    //       }
    //     }
    //   });
    // });

    // setError(message);
    // if (!message) {  
      setLoading(true);
      const selectedCity = cities.filter(c => c.id === city)
      let checkInDate = null;
      let checkOutDate = null;
      if(selectedCity  && selectedCity.length > 0){
        checkOutDate = selectedCity[0]?.checkOut ? moment(selectedCity[0]?.checkOut).format("YYYY-MM-DD") : null
        checkInDate = selectedCity[0]?.checkIn ? moment(selectedCity[0]?.checkIn).format("YYYY-MM-DD") : null
      }
      const newData = data
      if(newData && newData.rooms && newData.rooms[0]){
        newData.rooms.forEach((item)=>{
          item.check_in_date = checkInDate
          item.check_out_date = checkOutDate
        })
      }
      const result = await addHotelRooms(
        {
          ...newData,
          city_id: city,
          hotel_id: hotel,
          package_id: packageDetail?.id
        },
        setLoading
      );
      if (result) {
        cities.length &&
          cities.forEach((ele) => {
            if (ele.id === city) {
              ele.is_hotel_selected = true
            };
          });
        getHotelByCityId();
        handleCloseDialog(reset);
      }
    // }
  };

  const handleCloseDialog = async (reset) => {
    reset()
    setIsDialog((preState) => !preState);
  };

  const handleOpenDialog = async (id) => {
    getRoomByHotelId(id);
    setIsDialog((preState) => !preState);
    setError("");
  };

  const handleSearchChange = (event) => {
    setParams((previous) => ({ ...previous, search: event.target.value }));
  };

  useEffect(() => {
    getCheckOutCities();
  }, [id]);

  useEffect(() => {
    if (params?.id) getHotelByCityId();
  }, [params]);

  return (
    <>
      <Loading loading={loading} />
      <Paper
        sx={{
          marginY: "2%",
          marginX: "2%",
        }}
      >
        <Tab selectedId={city} cities={cities} changeCities={changeCities} />
        <Box sx={{ paddingX: 5, paddingY: 3 }}>
          <Header
            params={params}
            handleSearchChange={handleSearchChange}
            handleSubmit={handleSubmit}
          />
          <Table hotels={hotels} handleOpenDialog={handleOpenDialog} />
        </Box>
        <Room
          onSubmit={handleAddRoom}
          handleCloseDialog={handleCloseDialog}
          rooms={rooms}
          isDialog={isDialog}
          error={error}
        />
      </Paper>
    </>
  );
};

export default Hotel;
