import React from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Note = ({ title, description}) => {
  return description && (
    <Box sx={{  paddingLeft:2, paddingRight: 2}} >
      <Typography variant="h4" sx={{display:'flex',justifyContent:'center'}}  >
       {title}
      </Typography>
      <Typography variant="body2">
      <span dangerouslySetInnerHTML={{ __html: description}} />
      </Typography>
    </Box>
  )
}

export default Note