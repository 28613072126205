// import * as React from "react";

import { Box, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Marquee from "../../Components/Marquee";
import Form from "./Form";
import { selectStatues } from "../../utils";
import Loading from "../../Components/CommonComponents/Loading";

function Home() {

  const [status, setStatus] = useState(selectStatues.HotelWithTransport);
  const [loading, setLoading] = useState(false);

  const handleRadioButtonChange = (event) => {
    setStatus(Number(event.target.value)) 
  };
  
  return (
    <>
      {/* {loading && (<Loading loading={loading} />)} */}
      {/* <Header />
      <Marquee /> */}
      <Paper
        sx={{
          marginY: "5%",
          marginX: "10%",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          // height="20px"
          width="100%"
          p={2}
        >
          <label>
            <input
              type="radio"
              value={selectStatues.HotelWithTransport}
              checked={status === selectStatues.HotelWithTransport}
              onChange={handleRadioButtonChange}
            />
            Hotel With Transport
          </label>
          <label>
            <input
              type="radio"
              value={selectStatues.Hotel}
              checked={status === selectStatues.Hotel}
              onChange={handleRadioButtonChange}
            />
            Only Hotel
          </label>
          <label>
            <input
              type="radio"
              value={selectStatues.Transport}
              checked={status === selectStatues.Transport}
              onChange={handleRadioButtonChange}
            />
            Only Transport
          </label>
        </Box>

        <Box sx={{ padding: 4 }}>
          <Grid container spacing={2}>
            <Form status={status}  setLoading={setLoading} />
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

export default Home;
