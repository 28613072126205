import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  marqueeContainer: {
    overflow: "hidden",
    overflowY: "auto",
    whiteSpace: "nowrap",
     display: "flex",
    // marginTop: "10px",
    // marginLeft : '3px',
    justifyContent: "space-evenly",
    // marginX: "10% !important",
    // position: "relative",
    // width: '50%',
  },
  marqueeText: {
    position: "relative",
    animation: "$marquee 35s linear infinite",
    fontSize: "10px", // Large text size
    color: "green",
    marginTop: "20px",
    gap: "3px",
  },
  marqueeText1: {
    position: "relative",
    animation: "$marquee 35s linear infinite",
    fontSize: "10px", // Large text size
    color: "blue",
    marginTop: "40px",
    gap: "3px",
  },
  marqueeText2: {
    position: "relative",
    animation: "$marquee 35s linear infinite",
    fontSize: "10px", // Large text size
    color: "#9e4598",
    marginTop: "40px",
    gap: "3px",
  },
  marqueeText3: {
    position: "relative",
    animation: "$marquee 35s linear infinite",
    fontSize: "10px", // Large text size
    color: "black",
    marginTop: "40px",
    gap: "3px",
  },   
  marqueeText4: {
    position: "relative",
    animation: "$marquee 35s linear infinite",
    fontSize: "10px", // Large text size
    color: "#2c50df",
    marginTop: "40px",
    gap: "3px",
  },
  marqueeText5: {
    position: "relative",
    animation: "$marquee 35s linear infinite",
    fontSize: "10px", // Large text size
    color: "#7e20fb",
    marginTop: "40px",
    gap: "3px",
  },
  marqueeText6: {
    position: "relative",
    animation: "$marquee 35s linear infinite",
    fontSize: "10px", // Large text size
    color: "#d561b9",
    marginTop: "40px",
    gap: "3px",
  },
  marqueeText7: {
    position: "relative",
    animation: "$marquee 35s linear infinite",
    fontSize: "10px", // Large text size
    color: "#b49a39",
    marginTop: "40px",
    gap: "3px",
  },
  marqueeText8: {
    position: "relative",
    animation: "$marquee 35s linear infinite",
    fontSize: "10px", // Large text size
    color: "green",
    marginTop: "40px",
    gap: "3px",
  },
  marqueeText9: {
    position: "relative",
    animation: "$marquee 35s linear infinite",
    fontSize: "10px", // Large text size
    color: "#558186",
    marginTop: "40px",
    gap: "3px",
  },
  "@keyframes marquee": {
    "0%": {
      left: "100%",
    },
    "100%": {
      left: "-100%",
    },
  },
}));

const Marquee = () => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        // maxWidth: "1600px",
        // margin: "auto",
        //  marginTop: "10px",
        // overflowX: "auto",
        // boxShadow: "none",
        // marginRight: "350px",
        // display:'flex',
        justifyContent:'space-between',
        marginX: "10%",
        marginY: "1%",

      }}
    >
      <div className={classes.marqueeContainer}>
        <Typography variant="h6" className={classes.marqueeText}>
          <FontAwesomeIcon icon={faBullseye} />
          <span>{"Fresh Deal in Lillywoods Highland Beach Resort "}</span>
        </Typography>
        <Typography variant="h6" className={classes.marqueeText1}>
          <FontAwesomeIcon icon={faBullseye} />
          <span>{"Fresh Deal in Goa Continental Baga"}</span>
        </Typography>
        <Typography variant="h6" className={classes.marqueeText2}>
          <FontAwesomeIcon icon={faBullseye} />
          <span>{" Please get rate for only Transport also"}</span>
        </Typography>
        <Typography variant="h6" className={classes.marqueeText3}>
          <FontAwesomeIcon icon={faBullseye} />
          <span>{" Fresh Deal in Neelam the Grand"}</span>
        </Typography>
        <Typography variant="h6" className={classes.marqueeText4}>
          <FontAwesomeIcon icon={faBullseye} />
          {" Fresh Deal in Le Seasons Resort  "}
        </Typography>
        <Typography variant="h6" className={classes.marqueeText8}>
          <FontAwesomeIcon icon={faBullseye} />
          <span>
            {
              " Somy Plaza : 4th night offer continue till spetember (not in long weekend) "
            }
          </span>
        </Typography>
      </div>
      <div className={classes.marqueeContainer}>
        <Typography variant="h6" className={classes.marqueeText5}>
          <FontAwesomeIcon icon={faBullseye} />
          <span>{" Rate change in Shivam and scirrico "}</span>
        </Typography>
        <Typography variant="h6" className={classes.marqueeText6}>
          <FontAwesomeIcon icon={faBullseye} />
          <span>{"Rate increase in CANDOLIM HIDEOUT for Long Weekend  "}</span>
        </Typography>
        <Typography variant="h6" className={classes.marqueeText7}>
          <FontAwesomeIcon icon={faBullseye} />
          <span>{" Rate increase in CANDOLIM HIDEOUT for Long Weekend  "}</span>
        </Typography>
        <Typography variant="h6" className={classes.marqueeText8}>
          <FontAwesomeIcon icon={faBullseye} />
          <span>
            {
              " Somy Plaza : 4th night offer continue till spetember (not in long weekend) "
            }
          </span>
        </Typography>
      </div>
    </Box>
  );
};

export default Marquee;
