import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import SelectInput from "../../../Components/CommonComponents/SelectInput";
import NumberInput from "../../../Components/CommonComponents/NumberInput";
import { REQUIRED } from "../../../utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
  },
}));

const roomObject = {
  room_id: "",
  adult: "",
  extra_adult: "",
  max_occupancy: 0,
  child_with_mattress: "",
  child_without_mattress: "",
};

const defaultValues = {
  package_id: "",
  city_id: "",
  hotel_id: "",
  rooms: [roomObject],
};

const schema = yup.object().shape({
  rooms: yup.lazy(() =>
    yup.array().of(
      yup.object({
        room_id: yup.string().required(REQUIRED),
        // adult: yup.string().required(REQUIRED),
        extra_adult: yup
          .string()
          .test(
            "max_occupancy_extra_adult",
            "Please add less then or equal to room max occupancy. ",
            async function (value) {
              if(value){
                const max_occupancy = this.parent.max_occupancy;
                const total_add_occupancy =
                  Number(this.parent.adult) +
                  Number(this.parent.child_with_mattress) +
                  Number(this.parent.child_without_mattress) +
                  Number(value);
                return total_add_occupancy <= max_occupancy;
              }else {
                return true
              }
            }
          ),
        child_with_mattress: yup
          .string()
          .test(
            "max_occupancy_child_with_mattress",
            "Please add less then or equal to room max occupancy. ",
            async function (value) {
              if(value){
                const max_occupancy = this.parent.max_occupancy;
                const total_add_occupancy =
                  Number(this.parent.adult) +
                  Number(this.parent.extra_adult) +
                  Number(this.parent.child_without_mattress) +
                  Number(value);
                return total_add_occupancy <= max_occupancy;
              }else {
                return true
              }
            }
          ),
        child_without_mattress: yup
          .string()
          .test(
            "max_occupancy_check_child_without_mattress",
            "Please add less then or equal to room max occupancy. ",
            async function (value) {
              if(value){
                const max_occupancy = this.parent.max_occupancy;
                const total_add_occupancy =
                    Number(this.parent.adult) +
                    Number(this.parent.extra_adult) +
                    Number(this.parent.child_with_mattress) +
                    Number(value);
                return total_add_occupancy <= max_occupancy;
              }else {
                return true
              }
            }
          ),
      })
    )
  ),
});

const AddRoom = ({ onSubmit, handleCloseDialog, rooms, isDialog, error }) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting , isValid },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rooms",
  });
  return (
    <BootstrapDialog
      onClose={() => handleCloseDialog(reset)}
      aria-labelledby="customized-dialog-title"
      open={isDialog}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "transparent",
          },
        },
      }}
      maxWidth="lg"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Add Room
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => handleCloseDialog(reset)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        X
      </IconButton>

      <form onSubmit={handleSubmit((value) => onSubmit(value, reset))}>
        <DialogContent dividers>
          {fields.map((field, index) => (
            <Grid
              container
              spacing={2}
              marginTop="2px"
              width="100%"
              // padding={1}
              justifyContent="center"
            >
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <SelectInput
                  name={`rooms.${index}.room_id`}
                  control={control}
                  label="Select Room"
                  options={rooms}
                  optionKey={"room_type"}
                  fullWidth
                  onChange={(e, value) => {
                    const room_id = value?.id;
                    const max_occupancy = rooms.find(
                      (ele) => ele.id === room_id
                    )?.max_occupancy;
                    setValue(`rooms.${index}.room_id`, room_id);
                    setValue(`rooms.${index}.adult`, room_id ? 2 : 0);
                    setValue(`rooms.${index}.extra_adult`, "");
                    setValue(`rooms.${index}.max_occupancy`, max_occupancy);
                    setValue(`rooms.${index}.child_with_mattress`, "");
                    setValue(`rooms.${index}.child_without_mattress`, "");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={1.5}>
                <NumberInput
                  control={control}
                  label="Max Occupancy"
                  name={`rooms.${index}.max_occupancy`}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={1}>
                <NumberInput
                  control={control}
                  label="Adults"
                  name={`rooms.${index}.adult`}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={2.5}>
                <NumberInput
                  control={control}
                  label="Extra Adults (Above 12 Years)"
                  name={`rooms.${index}.extra_adult`}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={2.5}>
                <NumberInput
                  control={control}
                  label="Child (With Mattress 6-12 Years)"
                  name={`rooms.${index}.child_with_mattress`}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={2.5}>
                <NumberInput
                  control={control}
                  label="Child (Without Mattress 6-12 Years)"
                  name={`rooms.${index}.child_without_mattress`}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                {index > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    className="rounded"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    <RemoveIcon />
                  </Button>
                )}
                {fields.length - 1 === index && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="rounded"
                    sx={{ marginLeft: index ? 1 : 0 }}
                    type="button"
                    onClick={() => append(roomObject)}
                  >
                    <AddIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        {/* <Typography
          sx={{
            color: "red",
            display: "flex",
            justifyContent: "center",
            mt: 1,
          }}
        >
          {error}
        </Typography> */}
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            className="rounded"
            type="button"
            onClick={() => handleCloseDialog(reset)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className="rounded"
            type="submit"
            disabled={isSubmitting}
          >
            Add Hotel
          </Button>
        </DialogActions>
      </form>
    </BootstrapDialog>
  );
};

export default AddRoom;
