import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";

const Loading = ({loading,style}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: 'fixed',
        //zIndex:'999',
        width:'100%',
        height:'100%'
      }}
    >
      <PuffLoader  loading={loading} />
      {/* <RingLoader css={override} size={150} color={'#36D7B7'} loading={loading} /> */}
    </div>
  );
};


export default Loading;
